
import {useEffect, useRef, useState} from "react"
import {chatsPool} from "../codes/ChatsPool";
import {ctx, PAGES} from "../codes/Context";
import {observer} from "mobx-react-lite";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import LockIcon from '@mui/icons-material/Lock';
import {dateReFormat, UTCTodate} from "../utils";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {Box, Button, ButtonGroup, Container, Divider, Fab, IconButton, ListItemButton, ListItemIcon, Stack, Tabs, Tooltip, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {ActionSate, DialogStatus} from "../environment";
import {ActionPanel} from "./panels/ActionPanel";
import {Dialog} from "../codes/DataCord";



const ChatsPage = observer(() => {

  const [chatStatus, setChatStatus] = useState(DialogStatus.OPEN);


  function createNewChat() {
    ctx.AppState = {state: ActionSate.LOADING, text: "Открытие нового диалога"};
    chatsPool.createNewChat(
      (state: ActionSate, text?: string) => {
        ctx.AppState = {state: state, text: text};
        if (state === ActionSate.COMPLETED)
          ctx.currentPage = PAGES.PAGE_MESSAGES;
      }
    );
  }

  function closeChat(dialogid: number) {
    ctx.AppState = {state: ActionSate.LOADING, text: "Закрытие чата"};
    chatsPool.closeChat(
      dialogid, (state: ActionSate, text?: string) => {
        ctx.AppState = {state: state, text: text};
        if (state === ActionSate.COMPLETED)
          setChatStatus(DialogStatus.CLOSE);
      }
    );
  }



  useEffect(() => {
    ctx.AppState = {state: ActionSate.LOADING};
    chatsPool.loadChats(chatStatus,
      (state: ActionSate, text?: string) => {
        ctx.AppState = {state: state, text: text};

      });
  },
    [chatStatus]);


  const DataDisplay = chatsPool.Display(chatStatus);

  return (
    <>
      <Stack key="stak" sx={{minHeight: '24px'}} direction={"row"}>

        <ButtonGroup key="hbg" variant="text" sx={{mt: 1, ml: 2}}>
          <Button key="bhb" variant={chatStatus === DialogStatus.OPEN ? "contained" : "outlined"} color="primary"
            onClick={() => {setChatStatus(DialogStatus.OPEN)}}
            sx={{borderRadius: 9}} >Открытые </Button>
          <Button key="bhb2" variant={chatStatus === DialogStatus.CLOSE ? "contained" : "outlined"} color="primary"
            onClick={() => {setChatStatus(DialogStatus.CLOSE)}}
            sx={{borderRadius: 9}} >Закрытые </Button>
        </ButtonGroup>

        <ButtonGroup key="bh3" variant="text" sx={{mt: 1, ml: 2}}>
          <Fab key="bh4" color="primary" aria-label="add" size="small" sx={{opacity: "70%"}}
            onClick={() => createNewChat()}>
            <AddIcon key="bh5" />
          </Fab>
        </ButtonGroup>

      </Stack>


      <Box
        key="box"
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: 'auto',
          flexDirection: 'column',
          //height: 'calc(100dvh-76px)'
          mb: 1
        }}
      >

        <List key="list" sx={{bgcolor: 'background.paper'}} >
          {DataDisplay.map((chat: Dialog) => {
            return (
              <>
                <ListItem key={chat.dialogid + "l"} >
                  <ListItemIcon key={chat.dialogid + "i"}>
                    {(chatStatus === DialogStatus.OPEN)
                      ? (
                        <Stack key={chat.dialogid + "s"} direction="column">
                          <IconButton key={chat.dialogid + "b"} onClick={(event) => {
                            event.preventDefault();
                            closeChat(chat.dialogid);
                          }}>
                            <LockOpenIcon key={chat.dialogid + "lo"} color="primary" />
                          </IconButton>
                          <Typography key={chat.dialogid + "t"} fontSize={13}>
                            {
                            UTCTodate(chat.lastupdate)
                            }
                          </Typography>
                        </Stack>)
                      : (<Stack key={chat.dialogid + "sa"} direction="column">
                        <LockIcon key={chat.dialogid + "l1"} color="disabled" />
                        <Typography key={chat.dialogid + "t1"} fontSize={13}>
                          {
                          UTCTodate(chat.lastupdate)
                          }
                        </Typography>
                      </Stack>)
                    }
                  </ListItemIcon>
                  <ListItemButton key={chat.dialogid + "lb"} selected={(chatsPool.CurrentID === chat.dialogid)}
                    onClick={() => {
                      chatsPool.setCurrent(chat.dialogid);
                      ctx.currentPage = PAGES.PAGE_MESSAGES;
                    }}>

                    <ListItemText key={chat.dialogid + "lt"} primary={chat.team.teamds} secondary=
                      {chat.assignee?.userds}
                    ></ListItemText>
                    <Tooltip key={chat.dialogid + "tt"} title={JSON.stringify(chat)}>
                      <KeyboardDoubleArrowRightIcon key={chat.dialogid + "kb"} />
                    </Tooltip>
                  </ListItemButton>
                </ListItem>
                <Divider key={chat.dialogid + "dv"} variant="middle" component="li" />
              </>
            );
          })}
        </List>
      </Box>

    </>
  );
}

);
export {ChatsPage}