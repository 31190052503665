import {observer} from "mobx-react-lite";
import {ChatsPage} from "./app/ChatsPage";
import {MessagesPage} from "./app/MessagesPage";
import {ContextState, ContextStateExt, ctx, PAGES, PAGES_NAMES} from "./codes/Context";
import React, {useEffect, useState} from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {ThemeProvider} from "@emotion/react";
import {appTheme, mesTheme} from "./Theme";
import {autoDefferedProcess, runSocket} from "./network/socket/Sock";
import {InfoPanel, INFOTYPE} from "./app/panels/InfoPanel";
import {Box, Button, Input} from "@mui/material";
import {ActionPanel} from "./app/panels/ActionPanel";
import {chatsPool} from "./codes/ChatsPool";
declare const window: any;



function FlexBox(pros: {children: React.ReactNode}) {
    return (
        <Box
            sx={{
                width: "100%",
                height: '100dvh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >{pros.children}</Box>
    )
}


const App = observer(() => {

    const [$debugFlag, $setDebugFlag] = React.useState<boolean>(true);
    const [$debugUser, $setDebugUser] = React.useState<string>("");

    const [contetState, setContextStateExt] = useState<ContextStateExt>({state: ContextState.LOADING, extratext: []})

    const handlePopState = (event: any) => {
        // console.log('Location:', document.location);
        //console.log('State:', event.state);

        if ((document.location.pathname === "/" + PAGES_NAMES[0]) && (ctx.currentPage !== PAGES.PAGE_CHATS)) {
            ctx.currentPage = PAGES.PAGE_CHATS;
        }
        if ((document.location.pathname === "/" + PAGES_NAMES[1]) && (ctx.currentPage != PAGES.PAGE_MESSAGES)) {
            if (chatsPool.Current) {
                ctx.currentPage = PAGES.PAGE_MESSAGES;
            }
            else{
                ctx.currentPage = PAGES.PAGE_CHATS;
                
            }
        }
    };

    useEffect(() => {
    
        window.history.pushState({page: ""}, 'HelpChat(Beta test)', '/' + PAGES_NAMES[PAGES.PAGE_CHATS]);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        // setContextStateExt({state: ContextState.LOADING, extratext: []});
        // ctx.intitContext(window.callMeToken(), /*window.callMeID(),*/(result: ContextStateExt) => {
        //     setContextStateExt(result);
        //     if (result.state === ContextState.READY) {
        //         runSocket(0);
        //         autoDefferedProcess();
        //     }
        // })
        if (!$debugFlag) {
            setContextStateExt({state: ContextState.LOADING, extratext: []});
            ctx.intitContext($debugUser, /*window.callMeID(),*/(result: ContextStateExt) => {
                setContextStateExt(result);
                if (result.state === ContextState.READY) {
                    runSocket(0);
                    autoDefferedProcess();
                }
            })
        }

    }, [$debugFlag])


    return (
        <>
            <React.Fragment>
                <ThemeProvider theme={appTheme}>
                    <CssBaseline enableColorScheme />

                    {(!$debugFlag) ? (<>
                        <Container>
                            <FlexBox>
                                {(contetState.state === ContextState.LOADING) && (

                                    <InfoPanel paneltype={INFOTYPE.LOADING} text="Определение пользователя ..." extratext={contetState.extratext} />

                                )}
                                {(contetState.state === ContextState.SECERROR) && (

                                    <InfoPanel paneltype={INFOTYPE.ERROR} text="Ошибка определения пользователя, войдите через SFA повторно" extratext={contetState.extratext} />

                                )}
                                {(contetState.state === ContextState.ERROR) && (

                                    <InfoPanel paneltype={INFOTYPE.ERROR} text="Ошика определения пользователя" extratext={contetState.extratext} />

                                )}
                                {(contetState.state === ContextState.READY) && (
                                    <>
                                        {
                                            ctx.currentPage === PAGES.PAGE_CHATS
                                                ? (
                                                    <ChatsPage />
                                                )
                                                : (
                                                    <ThemeProvider theme={mesTheme}>
                                                        <MessagesPage />
                                                    </ThemeProvider>
                                                )
                                        }
                                    </>
                                )}
                                <ActionPanel />
                            </FlexBox>
                        </Container>
                    </>)
                        : (
                            <>
                                <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                                    <Box sx={{
                                        display: 'flex', width: '50%',
                                        alignItems: "center", justifyContent: "center", margin: "auto"
                                    }}>
                                        <Input
                                            placeholder='DEBUG'
                                            //placeholder="6146151:31104"
                                            value={$debugUser}
                                            onChange={(event) => $setDebugUser(event.target.value)}
                                        />
                                        <Button onClick={() => {

                                            $setDebugFlag(false);
                                        }
                                        }>
                                            Enter
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        )}




                </ThemeProvider>
            </React.Fragment>


        </>
    );
});
export default App;

