import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

enum INFOTYPE{
  LOADING,
  ERROR
}

function InfoPanel(props:{paneltype:INFOTYPE,text:string,extratext: string[]})
{
    return (
        <>
            <Box sx={{
              display: 'flex', width: '50%',
              alignItems: "start  ", justifyContent: "center", margin: "auto",
              flexDirection: 'column'
            }}>
              <Stack direction="row" alignItems={"center"}>
              {(props.paneltype===INFOTYPE.LOADING) && (<CircularProgress/>)}
              {(props.paneltype===INFOTYPE.ERROR) && (<ErrorIcon color="error"   fontSize="small"/>)}
              
              <Typography
                fontWeight="md"
                fontSize="md"
                flexWrap="wrap"
                overflow="auto"
                sx={{fontSize:"14px"}}
              > {props.text}
              </Typography>
              </Stack>

              {props.extratext.map(line => {
                  return (<>
                  
                   <Stack direction="row">
                   <RunningWithErrorsIcon fontSize="small"/>
                    <Typography
                      fontWeight="xs"
                      fontSize="xs"
                      flexWrap="wrap"
                      pl={3}
                      overflow="auto"
                      sx={{fontSize:"14px"}}
                    >{line}</Typography>
                    </Stack>
                  </>);
                })}

            </Box>


          
          </>
    );
}

export {InfoPanel,INFOTYPE}