import {observer} from "mobx-react-lite";
import {StoredMessage} from "../../codes/DataCord";
import SendingState from "./SendingState";
import {Box, Typography} from "@mui/material";
import {meBoxCSS, WrapTypography} from "./Decorator";


const MeBot = observer((props: {message:StoredMessage}) => {
    const {message} = props;
    return (
        <>
            <Box sx={{...meBoxCSS}}>
                <SendingState candidate={message.candidate}/>           
                <WrapTypography>
                     <>{message.content}</>
                </WrapTypography>
              </Box>
        </>
    )
});

export {MeBot}