import {CandidateState} from "../../environment";
import {Dialog, Message, Messages, Socketdata} from "../../codes/DataCord";
import {pool} from "../../codes/Pool";
import {chatsPool} from "../../codes/ChatsPool";

export function messageDispatcher(data: any) {

    if (data) {
        const socketdata: Socketdata = JSON.parse(data) as Socketdata;

        if (socketdata.datatype === "messages") {
            if (socketdata.data) {
                const messages: Messages = socketdata.data as Messages;
                if (messages.content) {
                    messages.content.forEach(element => {
                        handlingMessage(element)
                    });
                }
            }
        }
        else

            if (socketdata.datatype === "dialog") {
                if (socketdata.data) {
                    handlingDialog(socketdata.data as Dialog)
                }
            }
            else
                if (socketdata.datatype === "message") {
                    if (socketdata.data) {
                        handlingMessage(socketdata.data as Message)
                    }
                }
    }
}


function handlingDialog(dialog: Dialog) {
    chatsPool.updateChat(dialog);
}

function handlingMessage(message: Message) {

    chatsPool.addNews(message.dialogid,message.senttime);
    pool.storeMessage(message, CandidateState.NO);

}