import {observer} from "mobx-react-lite";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Box, Icon, ListItemButton, Typography} from "@mui/material";

import {hover} from "@testing-library/user-event/dist/hover";
import {StoredMessage} from "../../codes/DataCord";
import {pool} from "../../codes/Pool";
import {chatsPool} from "../../codes/ChatsPool";
import {TypeOfMessages} from "../../environment";
import {useState} from "react";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {notMeBoxCSS, varNoMeBackground, WrapTypography} from "./Decorator";


const sxx = {
    borderRadius: 2,
    mb: 0.2,
    p: 0.1,
    backgroundColor: "#c2beda",
    fontSize: 14,
    boxShadow:4
}
const hoverup = {
    '&:hover': {
        backgroundColor: "#4c476b",
        color: "#ffffff",
    }
}

const BotMe = observer((props: {message: StoredMessage}) => {

    const {message} = props;
    const completed = message.messagetypeid === TypeOfMessages.OPTDONE;

    function toggleOption(botstepid: number, botstepds: string) {
        pool.sendMessage(
            chatsPool.CurrentID,
            TypeOfMessages.OPTSELECT,
            botstepds,
            message.messageid,
            botstepid, botstepds);

    }

    return (
        <>
            <Box sx={{...notMeBoxCSS}}>
                <WrapTypography>
                    <>
                        {(message?.buttons?.length) && (message?.buttons?.length > 0)
                            && (<ContactSupportIcon {...completed ? {color: "disabled"} : {}} />)}
                        {message.content}
                    </>
                </WrapTypography>



                <Box sx={{backgroundColor: varNoMeBackground}}>

                    {message?.buttons && (
                        <List>
                            {message?.buttons.map((option) => {
                                return (
                                    <>
                                        <ListItem sx={{...sxx, ...completed ? {} : hoverup}} key={option.botstepid}>
                                            <ListItemButton
                                                disabled={completed}
                                                onClick={() => toggleOption(option.botstepid, option.botstepds)}>
                                                <WrapTypography fs={12}>
                                                    <>{option.botstepds}</>
                                                </WrapTypography>
                                            </ListItemButton>
                                        </ListItem>
                                    </>
                                )
                            })}
                        </List>
                    )}

                </Box>
            </Box>
        </>
    )

});

export {BotMe}