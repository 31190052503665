import {createTheme} from "@mui/material";
import './fonts.css';

export const appTheme = createTheme({
    palette: {
        primary: {
            light: "#413696",
            main: "#413696",
            dark: "#413696",
            contrastText: "#ffffff"

        },
        info: {
            light: "#109dd9",
            main: "#109dd9",
            dark: "#109dd9",
            contrastText: "#ffffff"

        },

    },
    typography: {
        fontSize: 13,//The default value is 14px
    }


});


export const mesTheme = createTheme({
    palette: {
        primary: {
            light: "#413696",
            main: "#413696",
            dark: "#413696",
            contrastText: "#ffffff"

        },
        info: {
            light: "#395e87",
            main: "#395e87",
            dark: "#395e87",
            contrastText: "#ffffff"

        },
        secondary:{
            light: "#726E8E",
            main: "#726E8E",
            dark: "#726E8E",
            contrastText: "#726e8e"

        }

    },

    typography: {
        fontSize: 13,//The default value is 14px
        fontFamily: [
            'Roboto',
            '"Segoe UI"',
            'Arial',
            'Calibri'
        ].join(','),
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: 13,//13,

                },
            },


        },
 
        MuiOutlinedInput: {
            defaultProps: {
                sx: {
                    fontSize: 13
                }
            }
        }

    },
});




export const panelTheme = createTheme({
    palette: {
        primary: {
            light: "#4f42b5",
            main: "#4f42b5",
            dark: "#4f42b5",
            contrastText: "#ffffff"

        },
        info: {
            light: "#395e87",
            main: "#395e87",
            dark: "#395e87",
            contrastText: "#ffffff"

        },
        secondary:{
            light: "#726E8E",
            main: "#726E8E",
            dark: "#726E8E",
            contrastText: "#726e8e"

        }

    },

    typography: {
        fontSize: 12,//10,//The default value is 14px
        fontFamily: [
             'Arial',
            'Pacifico','Calibri','Roboto',
            '"Segoe UI"',
           
        ].join(',')
        
        
    },

});

export {}