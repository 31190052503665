import {observer} from "mobx-react-lite";
import {Box, Stack} from "@mui/material";
import {StoredMessage} from "../../codes/DataCord";
import {meBoxCSS, notMeBoxCSS, QuotedBox, varMeBackground, WrapTypography} from "./Decorator";

const AgentMe = observer((props: {message: StoredMessage}) => {
    const {message} = props;
    return (
        <>
            <Box sx={{...notMeBoxCSS}}>
                <Stack direction="column" alignItems="flex-start">
                    {(message.replymessageid) && (<>
                        <QuotedBox fs={13} sx={{...meBoxCSS, pb: 0.4}} alignItems="flex-start">
                            <>{message.replycontent||"dasdasdasdasdas"}</>
                        </QuotedBox>
                    </>)}
                    <WrapTypography>
                        <>{message.content}</>
                    </WrapTypography>
                </Stack>
            </Box>
        </>
    )
});

export {AgentMe}