import {Box, Stack, Typography} from "@mui/material";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import {BorderColor, BorderStyle} from "@mui/icons-material";


export const varMeBackground = "#dcf2fc";
export const varMeBackgroundDarker = "#18688c";
export const varMeBackgroundInner = "#1293d3";
export const varNoMeBackground = "#e6e7ff";//"#f0f1ff";
export const varNoMeBackgroundInner = "#ccceff";
export const varNoMeBackgroundDarker = "#5c608a";

//https://www.geeksforgeeks.org/understanding-css-shadows-box-shadow-vs-drop-shadow/



export const meBoxCSS = {
    p: 1.25,
    borderRadius: 2,
    // borderStyle:"ridge",
    // border: 0.5,
    // borderColor:varMeBackgroundBorder,
    backgroundColor: varMeBackground,//"#e3e5fc"
    boxShadow:3
}

export const notMeBoxCSS = {
    p: 1.25,
    borderRadius: 2,
    backgroundColor: varNoMeBackground,
    boxShadow:3
}

export const notMeReplyCSS = {
    p: 0.5, 
    pl:1,
    //border: 1, borderStyle: "dashed", borderColor: varNoMeBackgroundDarker,
    boxSizing: "border-box",
    "borderRadius": "5px",
    //boxShadow: "-4px -4px 2px 1px #d9d9d9"
    boxShadow: "0 0 1.15rem rgb(99, 52, 140)",
    justifyContent: "center",
    alignItems: "center"
}

export const meReplyCSS = {
    p: 0.5, 
    pl:1,
    //border: 1, borderStyle: "dashed", borderColor: varNoMeBackgroundDarker,
    boxSizing: "border-box",
    "borderRadius": "5px",
    //boxShadow: "-4px -4px 2px 1px #d9d9d9"
    boxShadow: "0 0 1.15rem #1293d3",
    justifyContent: "center",
    alignItems: "center"
}

export const fileReplyCSS = {
    p: 0.5, 
    pl:1,
    //border: 1, borderStyle: "dashed", borderColor: varNoMeBackgroundDarker,
    boxSizing: "border-box",
    "borderRadius": "5px",
    //boxShadow: "-4px -4px 2px 1px #d9d9d9"
    boxShadow: "0 0 1.25rem #003300",
    justifyContent: "center",
    alignItems: "center"
}


export function WrapTypography({fs, sx, children}: {
    fs?: number | undefined,
    sx?: any | undefined,
    children: React.ReactElement
}) {

    return (
        <>
            <Typography
                sx={{
                    ...sx ? {...sx} : {},
                    ...fs ? {fontSize: fs} : {},
                    overflowWrap: "anywhere"

                }}
                component="span"
            >
                {children}
            </Typography>
        </>
    );
}


function Decorator({children}: {children: React.ReactElement}) {
    return (<>{children}</>)

}

export function QuotedBox({fs, sx, sxx, alignItems, children, endDecorator}: {
    fs?: number | undefined,
    sx?: any | undefined,
    sxx?: any | undefined,
    alignItems?: string,
    children: React.ReactElement,
    endDecorator?: React.ReactElement
}) {

    return (
        <>
            <Stack direction="row" alignItems={alignItems} sx={{...sxx}}>
                <WrapTypography fs={fs} sx={{...sx ? {...sx} : {}}}>
                    <>
                        {children}
                    </>
                </WrapTypography>
                {endDecorator &&
                    (
                        <Decorator children={endDecorator} />
                    )}
            </Stack>


        </>
    );
}


