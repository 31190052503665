import {Box, Button, CircularProgress, Fab, IconButton, InputAdornment, Stack, TextField, ThemeProvider, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {ctx, PAGES} from "../codes/Context";
import {Wrapper} from "./webparts/Wrapper";
import SendIcon from '@mui/icons-material/Send';
import {chatsPool} from "../codes/ChatsPool";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {mesTheme} from "../Theme";
import {useEffect, useState, useRef} from "react";
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import {ActionSate, FetchDirection, RestEnvironment, TypeOfMessages} from "../environment";
import {ActionPanel} from "./panels/ActionPanel";
import {pool} from "../codes/Pool";
import {StoredMessage} from "../codes/DataCord";
import {isnull, subsctruct} from "../utils";
import {fileReplyCSS, meReplyCSS, notMeReplyCSS, QuotedBox, varNoMeBackgroundDarker} from "./webparts/Decorator";
import CancelIcon from '@mui/icons-material/Cancel';



const MessagesPage = observer(() => {

    const [lightLoading, setLightLoading] = useState<{show: boolean, text?: string}>({show: false});
    const [messageText, setMessageText] = useState<string>("");
    const [showUploadButton, setShowUploadButton] = useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null)

    const refFile = useRef<HTMLInputElement>(null);

    useEffect(() => {
        ctx.AppState = {state: ActionSate.LOADING};
        pool.highlight = undefined;
        setFile(null);
        pool.loadMessages(chatsPool.CurrentID, undefined, FetchDirection.LAST, true,
            (state: ActionSate, text?: string, count?: number) => {
                ctx.AppState = {state: state, text: text};
                setShowUploadButton(isnull(count, 0) >= RestEnvironment.poolSize)
            });
    },
        [chatsPool.CurrentID]
    );


    const onInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files; // Get the FileList
        if ((files != null) && (files.length > 0)) {
            setFile(files[0]);
        }
    };

    return (
        <>

            <Fab color="primary" aria-label="add" size="medium"
                sx={{

                    position: "absolute",
                    top: 16,
                    left: 10,
                    opacity: "60%"
                }} onClick={() => {
                    pool.highlight = undefined;
                    ctx.currentPage = PAGES.PAGE_CHATS
                }} >
                <KeyboardDoubleArrowLeftIcon />
            </Fab>

            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    minHeight: 0,
                    px: 2,
                    py: 3,
                    overflowY: 'auto',
                    flexDirection: 'column-reverse',
                    mb: 1
                }}
            >

                {pool.Display(chatsPool.CurrentID).map((message: StoredMessage) => {
                    return (
                        <>
                            <Wrapper message={message} />
                        </>
                    )
                })

                }

                {showUploadButton && (
                    <Box
                        sx={{
                            // height: "100dvh",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: "center", justifyContent: "center"
                        }}
                    >
                        <Button variant="text" color="secondary"
                            sx={{fontSize: 13}}
                            onClick={() => {
                                pool.loadMessagesBack(chatsPool.CurrentID, (state: ActionSate, text?: string, count?: number) => {
                                    if (state === ActionSate.ERROR) {
                                        setLightLoading({show: false, text: text});
                                    }
                                    else {
                                        setLightLoading({show: false});
                                        setShowUploadButton(isnull(count, 0) >= RestEnvironment.poolSize);
                                    }
                                });
                                setLightLoading({show: true});
                            }}>
                            {(lightLoading.show) ?
                                (<><CircularProgress sx={{zoom: "50%"}} />
                                    Загрузка ранних сообщений...</>) :
                                (<><KeyboardDoubleArrowUpIcon />
                                    {(lightLoading.text) ? (<>{lightLoading.text}</>)
                                        : (<>Загрузить ранние сообщения</>)}
                                </>)

                            }

                        </Button>
                    </Box>
                )}

            </Box>
            {chatsPool.Editable &&
                (
                    <Box display="flex" flexDirection="column" >

                        {(pool.highlight) &&
                            (<Box display="flex" flexDirection="row" sx={{}}>
                                <QuotedBox
                                    sxx={{
                                        ...pool.highlightIsMe ? meReplyCSS : notMeReplyCSS
                                    }}
                                    alignItems="baseline"
                                    endDecorator={(
                                        <IconButton sx={{transform: 'scale(0.8)'}}
                                            onClick={() => {pool.highlight = undefined}} ><CancelIcon sx={{color: varNoMeBackgroundDarker}} />
                                        </IconButton>)}>
                                    <>{subsctruct(pool.highlightText || "", 46)}</>
                                </QuotedBox>
                            </Box>
                            )
                        }

                        {(file != null) &&
                            (<Box display="flex" flexDirection="row" sx={{}}>
                                <QuotedBox
                                    sxx={{
                                        ...fileReplyCSS
                                    }}
                                    alignItems="baseline"
                                    endDecorator={(
                                        <IconButton sx={{transform: 'scale(0.8)'}}
                                            onClick={() => {setFile(null);}} ><CancelIcon sx={{color: varNoMeBackgroundDarker}} />
                                        </IconButton>)}>
                                    <>{subsctruct("📎 " + file.name || "", 46)}</>
                                </QuotedBox>
                            </Box>
                            )
                        }

                        <Stack direction="row">

                            <TextField
                                id="filled-multiline-flexible"
                                //label="Сообщение"
                                multiline
                                maxRows={4}
                                variant="outlined"
                                sx={{width: "100%", }}
                                onChange={(e) => {
                                    setMessageText(e.target.value);
                                }}
                                value={messageText}
                                size="small"
                                InputProps={{   
                                    startAdornment: (
                                        <InputAdornment position="start" >
                                            <IconButton onClick={() => {refFile.current?.click()}}><AttachFileIcon color="primary" /></IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton  onClick={() => {
                                                if (messageText !== "") {
                                                    pool.sendMessage(
                                                        chatsPool.CurrentID,
                                                        TypeOfMessages.PLAINTEXT,
                                                        messageText,
                                                        pool.highlightID,
                                                        undefined, undefined, undefined, undefined,
                                                        pool.highlightText
                                                    )
                                                    pool.highlight = undefined;
                                                    setFile(null);

                                                }
                                                setMessageText("");
                                            }}><SendIcon color="primary" /></IconButton>
                                        </InputAdornment>
                                    )
                                }}

                            />


                        </Stack>
                    </Box>
                )}
            <input type="file" ref={refFile} style={{display: 'none'}} multiple={false}
                onChange={((e: React.ChangeEvent<HTMLInputElement>) => onInputFile(e))} />
        </>
    );
}

);
export {MessagesPage}