import {Typography} from "@mui/material";
import {CandidateState} from "../../environment";


export default function SendingState(msg:{candidate:number})
{
    return (
        <>
            {(msg.candidate === CandidateState.SENDING) && (
                        <Typography color="info" sx={{fontSize: 10, fontStyle: "italic"}} align="right">
                            отправка ...
                        </Typography>
                    )}
                    
                    {(msg.candidate === CandidateState.QUEUING) && (
                        <Typography color="info" sx={{fontSize: 10, fontStyle: "italic"}} align="right">
                            в очереди ...
                        </Typography>
                    )}
                    {(msg.candidate === CandidateState.WATING) && (
                        <Typography color="info" sx={{fontSize: 10, fontStyle: "italic"}} align="right">
                            подтверждение ...
                        </Typography>
                    )}
                    {(msg.candidate === CandidateState.ERROR) && (
                        <Typography color="info" sx={{fontSize: 10, fontStyle: "italic"}} align="right">
                            ошибка
                        </Typography>
                    )}
        </>
    )
}
export{}