import {Box, Container, Stack, ThemeProvider, Typography} from "@mui/material";
import {observer} from "mobx-react-lite"
import {ctx} from "../../codes/Context";
import ErrorIcon from '@mui/icons-material/Error';
import {Info} from "@mui/icons-material";
import {panelTheme} from "../../Theme";
import {ActionSate} from "../../environment";



const ActionPanel = observer(() => {
    return (
 
            <ThemeProvider theme={panelTheme}>
                <Box
               
                    sx={{
                        width: "100%",
                        height: '23px',
                        mt: 1,
                        mb: 1,
       
                    }}>
                    <Stack direction="row" spacing={2} >
                        <Typography align="left" >
                            Статус:
                        </Typography>
                        {(ctx.stateSock.state !== WebSocket.OPEN)
                            ? (
                                <Typography color="error" align="left" >
                                    Нет связи ({ctx.stateSock.queue})
                                </Typography>
                            )
                            : (
                                <Typography color="primary" align="left" >
                                    Онлайн
                                </Typography>
                            )}

                        <Stack direction="row" alignItems="center" gap={1}>
                            {(ctx.AppState.state === ActionSate.ERROR) && (<ErrorIcon color="error" />)}
                            {(ctx.AppState.state === ActionSate.LOADING) && (<Info color="primary" />)}
                            {((ctx.AppState.state === ActionSate.LOADING) ||
                                (ctx.AppState.state === ActionSate.ERROR))
                                && (
                                    <Typography sx={{fontStyle: "italic"}} color="info" align="center" >
                                        {ctx.AppState.text}
                                    </Typography>
                                )}
                        </Stack>
                    </Stack>
                </Box>
            </ThemeProvider>
          
    )
}
);
export {ActionPanel};