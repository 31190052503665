import {Box, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Attributes, Direct, pool, Who} from "../../codes/Pool";
import {MeAgent} from "./MeAgent";
import {AgentMe} from "./AgentMe";
import {BotMe} from "./BotMe";
import {MeBot} from "./MeBot";
import {UTCTodate, subsctruct} from "../../utils";
import {StoredMessage} from "../../codes/DataCord";
import {TypeOfMessages, TypesOfUsers} from "../../environment";
import ReplyIcon from '@mui/icons-material/Reply';
import {varMeBackgroundDarker, varNoMeBackgroundDarker} from "./Decorator";
import {chatsPool} from "../../codes/ChatsPool";


const Wrapper = observer((props: {message: StoredMessage}) => {

    const {message} = props;
    const attrs: Attributes = pool.is(message);
    const out: boolean = (attrs.direct === Direct.OUT);
    const bot: boolean = (attrs?.who === Who.BOT);
    const senderName = message.sender.userds || message.sender.userid

    function Highlight()
    {
        pool.highlight=message.uuid;
    }

    return (
        <>

            <Stack
                key={"wr" + message.uuid}
                direction="row"
                spacing={2}
                // eslint-disable-next-line eqeqeq
                flexDirection={out ? 'row-reverse' : 'row'}
                sx={{mt: 1}}
            >
                <Box sx={{maxWidth: '75%', minWidth: 'auto'}} >
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="baseline" >
                        {(attrs?.direct === Direct.IN) && (chatsPool.Editable) &&  (
                            <IconButton  sx={{ transform: 'scale(0.8)' }} 
                            onClick={()=>Highlight()} ><ReplyIcon sx={{color: varNoMeBackgroundDarker}}/></IconButton>)}
                        <Tooltip title={out ? "" : senderName}>
                            <Typography

                                fontSize={12} sx={{mb: 0.5, fontWeight: "600"}}
                                align={out ? 'right' : 'left'}
                                color={out ? 'info' : ([TypesOfUsers.BotFather, TypesOfUsers.Bots].indexOf(message.sender.usertypeid) < 0) ? "primary" : "secondary"}
                            >
                                {UTCTodate(message.senttime)}

                                <> {(!out) ? subsctruct(String(senderName), 25) : ""}
                                </>

                            </Typography>
                        </Tooltip>
                        {(attrs?.direct === Direct.OUT) && (chatsPool.Editable) && (
                            <IconButton onClick={()=>Highlight()}  sx={{ transform: 'scale(0.8)' }}><ReplyIcon sx={{color: varMeBackgroundDarker}}/></IconButton>)}
                    </Box>

                    {   (!out) && (!bot)
                        && (<AgentMe message={message} />)
                    }
                    {   (!out) && (bot)
                        && (<BotMe message={message} />)
                    }
                    {   (out) && (!bot)
                        && (<MeAgent message={message} />)
                    }
                    {   (out) && (bot)
                        && (<MeBot message={message} />)
                    }
    
                </Box>
            </Stack>


        </>
    )

});

export {Wrapper}