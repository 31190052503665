const API_VERSION = "v1a"


const RestEnvironment = {
    DialogUrl: "https://mp1.helpmetest.pepsico.digital/" + API_VERSION + "/api/dialogs",
    MessagesUrl: "https://mp1.helpmetest.pepsico.digital/" + API_VERSION + "/api/messages",
    UserUrl: "https://mp1.helpmetest.pepsico.digital/" + API_VERSION + "/api/users",
    LoginUrl: "https://mp1.helpmetest.pepsico.digital/" + API_VERSION + "/auth/lightlogin",
    // DialogUrl: "http://localhost:3081/"+API_VERSION+"/api/dialogs",
    // MessagesUrl: "http://localhost:3081/"+API_VERSION+"/api/messages",
    // UserUrl: "http://localhost:3081/"+API_VERSION+"/api/users",
    // LoginUrl:  "http://localhost:3081/"+API_VERSION+"/auth/lightlogin",
    poolSize: 43
}


enum FetchDirection {
    LAST = "last",
    FORWARD = "forward",
    BACK = "back"

}


enum ActionSate {
    COMPLETED,
    LOADING,
    ERROR,
    IDLE
}


const SocketEnvironment = {
    attempts: 600000,
    delay: 3000,
    //  url: "ws://localhost:3082/websocket/"+API_VERSION+"",
    url: "https://mp2.helpmetest.pepsico.digital/websocket/" + API_VERSION,
    defferDelay: 10000,
    ping: 0
}



enum DialogStatus {
    OPEN = 1,
    CLOSE = 2
}

enum TypeOfMessages {
    PLAINTEXT = 0,
    OPTBUTTONS = 1,
    SERVICE = 2,
    SHAKE = 3,// not use
    OPTSELECT = 4,// not use
    OPTDONE = 5
}
enum TypesOfUsers {
    BotFather = 1,
    Agents = 2,
    Users = 3,
    Bots = 4,
    Unknown = -1
}

enum CandidateState {
    NO = 0,
    SENDING = 1,
    WATING = 2,
    QUEUING = 3,
    ERROR = 4
}



export {
    ActionSate,
    TypeOfMessages, SocketEnvironment, DialogStatus, RestEnvironment, TypesOfUsers, CandidateState,
    FetchDirection
}