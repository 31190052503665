import {observer} from "mobx-react-lite";
import {Box, Stack} from "@mui/material";
import SendingState from "./SendingState";
import {StoredMessage} from "../../codes/DataCord";
import {meBoxCSS, notMeBoxCSS, QuotedBox, WrapTypography} from "./Decorator";


const MeAgent = observer((props: {message: StoredMessage}) => {
    const {message} = props;
    return (
        <>
            <Box sx={{...meBoxCSS}}>
                <SendingState candidate={message.candidate} />
                <Stack direction="column" alignItems="flex-end">
                    {(message.replymessageid) && (<>
                        <QuotedBox fs={13} sx={{...notMeBoxCSS, pb: 0.4
                        }} alignItems="flex-end">
                            <>{message.replycontent}</>
                        </QuotedBox>
                    </>)}
                    <WrapTypography>
                        <>{message.content}</>
                    </WrapTypography>
                </Stack>
            </Box>
        </>
    )
});

export {MeAgent}